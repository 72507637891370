<template>
<div class="examin" v-loading="pageLoading">
  
  <!-- <div class="result" v-if="info">
    <p>考试详情</p>
    (正确：<span class="green">{{info.on_number}}题</span>  错误：<span class="red">{{info.off_number}}题</span> )
  </div> -->
  <div class="list">
    <!-- 还是0错 1对 2未作答 -->
     <p class="item" :key="index" :class="{red: item.status== 0,green: item.status == 1}"  v-for="(item,index) in list"  @click="toDetail(index)">
        {{index+1}}
     </p>
  </div>
</div>
</template>

<script>
export default {
  name: "lesson",
  data() {
    return {
      pageLoading: true,
      info:null,
      list:[]
    };
  },
  async mounted() {
      this.id = this.$route.query.id
      this.getData();
    
  },
  destroyed() {
    
  },
  computed: {
   
  },
  methods: {
   toDetail(index) {
      this.$router.push({
        path:'./examinList',
        query:{
          currentIndex:index,
          id:this.$route.query.test_id,
          resultId:this.id,
          isReview: this.$route.query.isReview,
          type:this.$route.query.type
        }
      })
   },
   async getData() {
      this.pageLoading = true;
      let res = await this.$api({
        method: "get",
        url: "/api/test/detail",
        data: {
          test_id: this.$route.query.test_id,
            uid: this.$route.query.uid,
            report_id:  this.$route.query.report_id,
        },
      });
      this.pageLoading = false;
      if (res.code == 0) {
        this.list = res.data;
      } else {
        this.$toast(res.msg);
      }
    },
 
  },
  beforeRouteUpdate(to, from, next) {
    console.log("beforeRouteUpdate");
    console.log(to);
    next();
  },
};
</script>

<style lang="scss" scoped>
.examin {
   min-height: calc(100vh - 360px);
   overflow-y: auto;
   background: #f6f9fc;
   margin: 30px auto;
  color: #333333;
  width:1200px;
  border-radius: 8px;
  padding: 25px 58px;
  box-sizing: border-box;
  .result{
    font-size: 18px;
    p{
      padding-bottom: 10px;
      font-size: 20px;
      color: #333333;
    }
  }
.result .green{
  color: #5FD131;
  margin-right: 14px;
}
.result .red{
  color: #FF4D41;
}
.list{

  }
  .list .item{
    float: left;
    width: 34px;
    height: 34px;
    border: 1px solid #cbced0;
    text-align: center;
    line-height: 34px;
    border-radius: 50%;
    margin: 10px;
    margin-bottom: 20px;
    font-size: 16px;
    box-sizing: border-box;
    cursor: pointer;
  }

  .list .red{
    background: #e85555;
    border-color: #e85555;
    color: #fff;
  }
  .list .green{
    background: #3cd0b6;
    border-color: #3cd0b6;
    color: #fff;
  }
}
</style>

<style lang="scss">
</style>
